import type { Chain } from "@fscrypto/domain/chain";
import type { UserWallet } from "@fscrypto/domain/earn";
import FlipKitConnectWallet from "@fscrypto/flip-kit/connect-wallet";
import { ClientOnly } from "remix-utils/client-only";
import { Skeleton } from "./ui/skeleton";

const ConnectWallet = ({
  chain,
  onSelect,
  children,
}: {
  chain: Chain;
  onSelect: (wallet: UserWallet) => void;
  children?: React.ReactNode;
}) => {
  return (
    <ClientOnly fallback={<Skeleton className="h-10 w-full" />}>
      {() => (
        <FlipKitConnectWallet
          chain={chain}
          // api={...} // Api is not yet implemented, meaning this will use Gumby's endpoints for wallet management by default.
          config={{
            dynamicEnvironmentId: window.ENV.DYNAMICXYZ_ENV_ID,
            myNearWalletCallbackUrl: `${window.location.protocol}//${window.location.host}/earn/my-near-wallet-connect`,
            theme: "dark",
          }}
          onSelectWallet={onSelect}
        >
          {children}
        </FlipKitConnectWallet>
      )}
    </ClientOnly>
  );
};

// default export so we can lazy load the component
export default ConnectWallet;
